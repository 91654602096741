<template>
  <div class="trmeMaterial">
    <img class="bg" :src="data.url+'trme/bg.png'" alt="图片加载失败">
    <div class="content">
      <div class="logo">
        <img :src="data.url+'trme/materialTitle.png'" alt="图片加载失败">
      </div>
      <div class="title">
        TRME 营销物料大全<br/>
        一站式承包您的日常沟通内容
      </div>
      <div class="areaBox">
        <img class="titleImg" :src="data.url+'trme/title1.png'" alt="" srcset="">
        <div class="list">
          <div class="productImg">
            <img :src="data.url+'trme/chengfen.png'" alt="" srcset="">
          </div>
          <div class="info">
            <div class="row" @click="jumpPath('chengfen1')">成分解析海报 ></div>
            <div class="row" @click="jumpPath('chengfen2')">血橙科普图文 ></div>
            <div class="row" @click="jumpPath('chengfen3')">减重科普图文 ></div>
          </div>
        </div>
        <div class="list">
          <div class="productImg">
            <img :src="data.url+'trme/kepu.png'" alt="" srcset="">
          </div>
          <div class="info">
            <div class="row" @click="jumpPath('kepu1')">双胞菇粉裹油力对比视频 ></div>
            <div class="row" @click="jumpPath('kepu2')">双胞菇粉裹油力实验视频 ></div>
            <div class="row" @click="jumpPath('kepu3')">血橙萃取物视频 ></div>
            <div class="row" @click="jumpPath('kepu4')">双胞菇提取物 ></div>
          </div>
        </div>
      </div>
      <div class="areaBox">
        <img class="titleImg" :src="data.url+'trme/title2.png'" alt="" srcset="">
        <div class="areaInfo">
          <div class="row">
            <div class="rowItem" @click="jumpPath('tixin1')">饮食建议海报 ></div>
            <div class="rowItem" @click="jumpPath('tixin2')">运动建议海报 ></div>
          </div>
          <div class="row" @click="jumpPath('tixin3')">
            减脂知识分享图文 >
          </div>
        </div>
      </div>
      <div class="areaBox">
        <img class="titleImg" :src="data.url+'trme/title3.png'" alt="" srcset="">
        <div class="areaInfo">
          <div class="row">
            <div class="rowItem" @click="jumpPath('jianzhen1')">见证海报 ></div>
            <div class="rowItem" @click="jumpPath('jianzhen2')">见证视频 ></div>
          </div>
          <div class="row" @click="jumpPath('jianzhen3')">
            见证图文 >
          </div>
        </div>
      </div>
      <div class="areaBox" style="padding-bottom: 0.16rem;">
        <img class="titleImg" :src="data.url+'trme/title4.png'" alt="" srcset="">
        <div class="areaInfo" style="margin-bottom: 0.07rem;">
          <div class="row" @click="jumpPath('chuangyi1')">
            见证官鲜体验 >
          </div>
        </div>
        <div class="areaInfo">
          <div class="row">
            <div class="rowItem" @click="jumpPath('chuangyi2')">气球视频></div>
            <div class="rowItem" @click="jumpPath('chuangyi3')">牛仔裤视频></div>
          </div>
          <div class="row">
            <div class="rowItem" @click="jumpPath('chuangyi4')">婚纱视频></div>
            <div class="rowItem" @click="jumpPath('chuangyi5')">创意海报合集></div>
          </div>
        </div>
        <div class="areaInfo" style="margin-top: 0.07rem;">
          <div class="row">
            <div class="rowItem" @click="jumpPath('xinran')">型燃篇></div>
            <div class="rowItem" @click="jumpPath('naixi')">美代纤奶昔篇></div>
          </div>
          <div class="row">
            <div class="rowItem" @click="jumpPath('kalu')">咔路片篇></div>
            <div class="rowItem" @click="jumpPath('daotui')">倒退篇></div>
          </div>
        </div>
      </div>
      <div class="empty"></div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url+'trme/backBtn.png'" alt="">
    </div>
  </div>
</template>
<script setup>
  import {reactive,onMounted} from 'vue';
  import { useRouter } from "vue-router";
  import {baiduTotal} from "../../js/common.js";
  import { prviewVideoBox } from "../../js/until";
  const router = useRouter();
  let data=reactive({
    url:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/',//图片路径
    linkObj:{
      'chengfen1':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0313-nTBHyQAABwD9cetARxBEJA9SmTN77&idx=0&sn=00b37f37378fbec7a935af726de7f1d1&notreplace=true',
      'chengfen2':'https://mp.weixin.qq.com/s/ymcUe8m6_uur73SR1TGSOQ',
      'chengfen3':'https://mp.weixin.qq.com/s/I_t90pn0-6691K83e_8XUg',
      'kepu1':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/kepu1.mp4',
      'kepu2':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/kepu2.mp4',
      'kepu3':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/kepu3.mp4',
      'kepu4':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/kepu4.mp4',
      'tixin1':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0313-WZbhPwAABwA51022q7Xi0gEfYuV77&idx=0&sn=70798f34be0e5242a2a788299be52c02&notreplace=true',
      'tixin2':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0313-QqBfcwAABwAi4fP6e4RcngsaLiI77&idx=0&sn=df4ac0dafaac60bdc9f02a79da84085c&version=4.1.3.6008&platform=win',
      'tixin3':'https://mp.weixin.qq.com/s/eFiRMJsm53BVdPyMKB-uwA',
      'jianzhen1':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0313-FGmAmwAABwB0KCwSzrODdgv168l77&idx=0&sn=42eb4c80f7c4343093f77c90a4b29cad&version=4.1.3.6008&platform=win',
      'jianzhen2':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/jianzhen.mp4',
      'jianzhen3':'https://mp.weixin.qq.com/s/JqNS6fWoIwtvoiNkbKpxLQ',
      'chuangyi1':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/chuangyi1.mp4',
      'chuangyi2':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/qiqiu.mp4',
      'chuangyi3':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/niuzhaiku.mp4',
      'chuangyi4':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/hunsha.mp4',
      'chuangyi5':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0313-Db2zEQAABwBt-B_Yw6Ow-AznF9Z77&idx=0&sn=50d92577df864d5673fa54965f3dd8a2&notreplace=true',
      'xinran':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/xinran.mp4',
      'naixi':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/naixi.mp4',
      'kalu':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/kalu.mp4',
      'daotui':'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/images/trme/material/daotui.mp4'
    },
    diskObj:{
      'kepu1':'https://nudrive.cn.nuskin.com/file/3hf11qij6xvliumy008z3pwl0f7spxuu#',
      'kepu2':'https://nudrive.cn.nuskin.com/file/tft112og5xr4xqnxsb7be6q26gwyomdz#',
      'kepu3':'https://nudrive.cn.nuskin.com/file/1j5cfk48bt88knsx19sj27vo7w47ohy0#',
      'kepu4':'https://nudrive.cn.nuskin.com/file/ix294kui8969vvs3m4tg1b6ukf4opepr#',
      'jianzhen2':'https://nudrive.cn.nuskin.com/file/47eddp0kdtguvwn6suq38ewijc62z6rd#',
      'chuangyi1':'https://nudrive.cn.nuskin.com/file/07iu5fl8prz0u57zpdyak04cmalguivn#',
      'chuangyi2':'https://nudrive.cn.nuskin.com/file/6f1hsr1b09xbg0jbi14d003kc0kfq796#',
      'chuangyi3':'https://nudrive.cn.nuskin.com/file/vepdz7rrm024wtj0gi0c7otb0dt6413y#',
      'chuangyi4':'https://nudrive.cn.nuskin.com/file/e1uruwnaw5mqpw3hox3oybiwbam6ztjw#',
      'xinran':'https://nudrive.cn.nuskin.com/file/sfd1lmsm2pcd71i4isr0ky1sg3cbz78w#',
      'naixi':'https://nudrive.cn.nuskin.com/file/2rjploy76u18nd9zcm1eji74npwyitlk#',
      'kalu':'https://nudrive.cn.nuskin.com/file/0j8eqqcw5mwg6aan64nhqs9jrdzy3xnh#',
      'daotui':'https://nudrive.cn.nuskin.com/file/gqycxn6c4logxxx2t90kwsnmgns2bmbs#'
    }
  })
  //返回首页
  let backIndex=()=>{
    router.replace('/index');
  }
  let jumpPath = (type)=>{
    if(data.diskObj[type]){
      //查看视频
      prviewVideoBox({
        videoUrl:data.linkObj[type],
        qcodeUrl:'',
        wangpanPath:data.diskObj[type]
      })
    }else{
      window.location.href = data.linkObj[type];
    }
  }
  onMounted(()=>{
    baiduTotal();
  })
</script>
<style lang="scss" scoped>
.trmeMaterial{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-top: 0.5rem;
  background: #BEDBC3;
  .bg{
    width: 100%;
    position: absolute;
    top: -0.9rem;
    left: 0;
    z-index: 1;
  }
  .content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
  }
  .logo{
    width: 1.95rem;
    overflow: hidden;
    font-size: 0;
    img{
      max-width: 100%;
    }
  }
  .title{
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #02CCBE;
    line-height: 0.18rem;
    margin: 0.22rem 0 0.2rem;
  }
  .areaBox{
    width: 3.5rem;
    background: linear-gradient(90deg, #00A99B, #06CFBE, #00A99C);
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.15rem 0.06rem 0.08rem;
    box-sizing: border-box;
    margin-bottom: 0.12rem;
    .titleImg{
      height: 0.28rem;
      margin-bottom: 0.1rem;
    }
    .list{
      width: 3.39rem;
      background: linear-gradient(90deg, #CCCCCC 0%, #FFFFFF 50%, #CCCCCC 100%);
      box-shadow: 2px 2px 16px 0px #09C6B9;
      border-radius:0.1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.06rem;
      &:last-child{
        margin-bottom: 0;
      }
      .productImg{
        width: 1.17rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 0.9rem;
        }
      }
      .info{
        flex: 1;
        width: 0;
        border-left: 1px solid rgba(35, 24, 21, .2);
        .row{
          width: 100%;
          height: 0.44rem;
          text-align: center;
          line-height: 0.44rem;
          font-size: 0.12rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #231815;
          border-bottom: 1px solid rgba(35, 24, 21, .2);
        }
      }
    }
    .areaInfo{
      width: 3.39rem;
      background: linear-gradient(90deg, #CCCCCC 0%, #FFFFFF 50%, #CCCCCC 100%);
      box-shadow: 2px 2px 16px 0px #09C6B9;
      border-radius:0.1rem;
      padding:  0 0.19rem;
      box-sizing: border-box;
      .row{
        width: 100%;
        height: 0.32rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #231815;
        border-bottom: 1px solid rgba(35, 24, 21, .2);
        padding: 0.06rem 0;
        &:last-child{
          border-bottom: none;
        }
        .rowItem{
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          &:first-child{
            border-right: 1px solid rgba(35, 24, 21, .2);
          }
        }
      }
    }
  }
}
</style>